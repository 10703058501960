//
// footer.scss
//

/*********************************/
/*         Footer                */
/*===============================*/

footer {
    position: relative;
    color: $gray-500;
    .footer-py-30 {
        padding: 30px 0;        
    }
    
    .text-foot {
        color: $gray-500;
    }
}