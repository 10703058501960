//
// helper.scss
//

.section {
    padding: 100px 0;
    position: relative;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
}

.section-two {
    padding: 48px 0;
    position: relative;
}

.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

//Title
.title-heading {
    .heading {
        font-size: 42px !important;
        letter-spacing: 0.2px;
        line-height: 1.5;
        @media (max-width: 768px) {
            font-size: 34px !important;
        }

        &.sub-heading {
            font-size: 32px !important;
            @media (max-width: 768px) {
                font-size: 28px !important;
            }
        }
    }
    .letter-spacing {
        letter-spacing: 1px;
    }
    .para-desc {
        font-size: 18px;
        letter-spacing: 0.5px;
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
}

.section-title {
    position: relative;
    .title {
        letter-spacing: 0.5px;
        font-size: 36px !important;
        @media (max-width: 768px) {
            font-size: 26px !important;
        }
    }
} 

.para-desc {
    max-width: 550px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-60 {
    margin-bottom: 60px;
}

//Shapes
.shape {
    position: absolute;
    // pointer-events: none;
    right: 0;
    bottom: -1px;
    left: 0;
    @media (max-width: 425px) {
        bottom: -4px;
    }
    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }
    &.crypto-bottom {
        bottom: 0 !important;
        .crypto-border {
            border-top: 1px dashed rgba($white, 0.2);
        }
    }
}

@media (max-width: 767px) {
    .laptop-image img {
        max-width: 100%;
        height: auto;
    }
}

.home-shape-arrow {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 25px;
    z-index: 1;
    left: 0;
    i {
        background: $light;
        height: 50px;
        width: 50px;
        margin: 0 auto;
        line-height: 50px;
        text-align: center;
        box-shadow: $shadow;
        border-radius: 30px;
    }
    .arrow-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        display: inline-block;
    }
}

//Feature post placeholder
.feature-posts-placeholder {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 66.6%;

    @media (max-width: 425px) {
        height: 80%;
    }
}

//CTA
.play-icon {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 0;
    left: 0;
    text-align: center;
    i {
        height: 75px;
        -webkit-text-stroke: 2px $primary;
        -webkit-text-fill-color: transparent;  
        width: 75px;
        font-size: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover {
            -webkit-text-stroke: 2px $primary;
            -webkit-text-fill-color: $primary;
        }
    }
}

.play-btn {
    height: 78px;
    width: 78px;
    font-size: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    i {
        -webkit-text-stroke: 2px $primary;
        -webkit-text-fill-color: transparent;  
        &:hover {
            -webkit-text-stroke: 2px $primary;
            -webkit-text-fill-color: $primary;
        }
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
    }
}

@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }
}

.features-absolute {
    position: relative;
    z-index: 2;
    transition: all 0.5s ease;
}

.features-absolute {
    margin: -200px 0 0px;

    @media (max-width: 768px) {
        margin: -140px 0 0;
    }
    
    &.blog-search {
        margin: -120px 0 0px;

        @media (max-width: 768px) {
            margin: -90px 0 0px;
        }
    }
}