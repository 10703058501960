//
// _pricing.scss
//

/*********************************/
/*         Pricing               */
/*===============================*/

.pricing {
    transition: all 0.5s ease;
    .title {
        transition: all 0.5s ease;        
    }
    .icons {
        i {
            background: linear-gradient(45deg, $primary, $danger) !important;
            height: 90px;
            width: 90px;
        }
    }
}