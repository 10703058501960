//
// variables.scss
//

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

// Color
$white:                     #ffffff;

$primary:                   #314CB6;
$blue:                      #6666FF;
$secondary:                 #5a6d90;
$success:                   #52CC99;
$warning:                   #FFBF66;
$info:                      #66CCFF;
$danger:                    #D7263D;
$purple:                    #aa65e8;
$dark:                      #24272c;
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f2f6fa;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d; 
$gray-700:                  #495057;

//Body Background
$shadow:                    0 0 3px rgba($dark,.15);
$shadow-md:                 0 5px 13px rgba($dark, .2);
$shadow-lg:                 0 25px 25px rgba($dark, 0.05);
$footer:                    lighten($black, 6%);

// Base font
$font-size-base:            16px;
$font-size-menu:            14px;

$font-family-base:          'DM Sans', sans-serif;
$font-family-secondary:     'DM Sans', sans-serif;

// Overlay
$overlay:                   rgba($black, 0.75);
$card-overlay:              linear-gradient(to bottom, transparent 0%, transparent 25%, transparent 35%, rgba($black, 0.9) 100%);

$colors: (
    "primary": $primary,
    "blue": $blue,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "footer": $footer
);

$heading-font-sizes: (
    "h1, .fs-1": 42px,
    "h2, .fs-2": 36px,
    "h3, .fs-3": 30px,
    "h4, .fs-4": 24px,
    "h5, .fs-5": 18px,
    "h6, .fs-6": 16px,
);

$display-font-sizes: (
    "display-1": 80px,
    "display-2": 72px,
    "display-3": 64px,
    "display-4": 56px,
    "display-5": 48px,
    "display-6": 40px,
)