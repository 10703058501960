//
// _home.scss
//

/*********************************/
/*            Home               */
/*===============================*/

@mixin home-common {
    background-size: cover !important;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-home {
    height: 100vh;    
    @include home-common();
    @media (max-width: 767px) {
        padding: 150px 0 !important;
        height: auto;
    }
}

.bg-home-75vh {
    height: 75vh !important;    
    @include home-common();
    @media (max-width: 767px) {
        padding: 120px 0 !important;
        height: auto;
    }
}

.bg-home-70vh {
    height: 70vh !important;    
    @include home-common();
    @media (max-width: 767px) {
        padding: 120px 0;
        height: auto;
    }
}

.bg-half-260 {
    padding: 260px 0;    
    @include home-common();
    @media (max-width: 768px) {
        padding: 150px 0;
        height: auto;
    }
}

.bg-half-360 {
    padding: 360px 0;    
    @include home-common();
    @media (max-width: 768px) {
        padding: 150px 0;
        height: auto;
    }
}

.bg-half-170 {
    padding: 170px 0;    
    @include home-common();
    @media (max-width: 767px) {
        padding: 120px 0;
        height: auto;
    }
}
.bg-half-130 {
    padding: 130px 0 104px;    
    @include home-common();
}
.bg-half-100 {
    padding: 100px 0;    
    @include home-common();
}

.bg-single-home,
.aboutme {
    padding-top: 36px;
    padding-bottom: 100px;
    
    @media (max-width: 768px) {
        padding: 100px 0 100px;
    }
}

.bg-hero {
    padding-top: 36px;
    padding-bottom: 100px;
    
    @media (max-width: 768px) {
        padding: 74px 0 100px;
    }
}

.bg-half {
    padding: 200px 0 100px;

    @media (max-width: 768px) {
        padding: 160px 0 60px;
    }
    @include home-common();
}

//Saas
.home-dashboard {
    img {
        position: relative;
        top: 60px;
        z-index: 1;
    }
}

//Bg Video Hero
.bg-video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
    iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
     }
}

//text type home
.text-type-element {
    z-index: 2;
    &:after {
        content: "";
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: $warning;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 5px;
        z-index: -1;
        transition: all 0.5s ease;
    }
}


//Cover Home
.auth-hero {
    min-height: 100vh;
    @media (max-width: 768px) {
        min-height: 0;
        .my-lg-auto {
            margin: 60px 0;
        }
    }
}

.cover-user {
    .container-fluid{
        max-width: 100%;
        .padding-less{

            @media (max-width: 768px) {
                position: relative !important;
                height: 400px !important;
            }

            &.img {
                background: scroll center no-repeat;
                background-size: cover;
                position: absolute;
                height: 100%;
            }
        }
    }    
    .cover-user-img {
        height: 100vh;

        @media (min-width: 1025px) {
            padding: 0px 16%;
        }
        
        @media (max-width: 768px) {
            align-items: start !important;
            height: auto !important;
            padding: 30px;
        }
    }

    
    @media (max-width: 768px) {
        .cover-user-content {
            text-align: left !important;
        }    
        .cover-my-30 {
            margin: 30px 0;
        }
    }
}