//
// _contact.scss
//

/*********************************/
/*         Contact us            */
/*===============================*/

.error {
    margin: 8px 0px;
    display: none;
    color: $danger;
}
  
#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message,
#success_page {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 30px;
}

.error_message {
    background-color: rgba($danger, 0.1) !important;
    border: 2px solid rgba($danger, 0.1) !important;
    color: $danger !important;
    font-size: 16px;
}

.contact-loader {
    display: none;
}
  
#success_page {
    background-color: rgba($success, 0.1) !important;
    border: 2px solid rgba($success, 0.1) !important;
    color: $success !important;
    p {
        margin-bottom: 0 !important;
    }
}